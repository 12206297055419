import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TestimonialOne = () => {
    return(
        <>
        {/* Testimonial Section */}
        <section className="testimonial-section section-padding-all">
            <div className="default-container">
            <div className="row clearfix">
                <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                {/*Sec Title*/}
                <div className="sec-con-title text-center centered mx-auto">
                    <div className="con-title-text con-title-border-l">More Then &amp; Satisfaction</div>
                    <h2>What Customer Says?</h2>
                    <div className="text">Testimonials from our existing customers</div>
                </div>
                </div>
                <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                <OwlCarousel 
                    items={1}
                    loop={true}
                    nav={false}
                    dots={true}
                    autoplay={true}
                    animateIn={'fadeIn'}
                    animateOut={'fadeOut'} className="text-center single-item-carousel owl-carousel">
                    <div className="business-client-box p-4">
                    <div className="client-box-img mb-3 mx-auto">
                        <img src={`images/testimonials-avatar-1.png`} alt="Customer Review" className="img-fluid rounded-circle" />
                    </div>
                    <div className="clients-name">
                        <h5 className="mb-0"><strong>domagojsamardzic123</strong></h5>
                        <small className><strong>Themeforest Customer</strong></small>
                    </div>
                    <p className="text pt-3 mx-auto">That explained it perfectly. I added the following to the "source" site and it worked! </p>
                    </div>
                    <div className="business-client-box p-4">
                    <div className="client-box-img mb-3 mx-auto">
                        <img src={`images/testimonials-avatar-1.png`} alt="Customer Review" className="img-fluid rounded-circle" />
                    </div>
                    <div className="clients-name">
                        <h5 className="mb-0"><strong>Standy2522</strong></h5>
                        <small className><strong>Themeforest Customer</strong></small>
                    </div>
                    <p className="text pt-3 mx-auto">I've seen templates everywhere online. Is hard for me to decide, some are costly and some are not clear. You made possible, affordable for developers / designers. Complete with documentation and easy to use. </p>
                    </div>
                    <div className="business-client-box p-4">
                        <div className="client-box-img mb-3 mx-auto">
                            <img src={`images/testimonials-avatar-1.png`} alt="Customer Review" className="img-fluid rounded-circle" />
                        </div>
                        <div className="clients-name">
                            <h5 className="mb-0"><strong>Kata</strong></h5>
                            <small className><strong>Templatemonster Customer</strong></small>
                        </div>
                        <p className="text pt-3 mx-auto">Very good theme and helpful author! I asked for a few changes, but I got the fixed new versions in a few days. I can only recommend!</p>
                    </div>
                    <div className="business-client-box p-4">
                        <div className="client-box-img mb-3 mx-auto">
                            <img src={`images/testimonials-avatar-1.png`} alt="Customer Review" className="img-fluid rounded-circle" />
                        </div>
                        <div className="clients-name">
                            <h5 className="mb-0"><strong>Ventsislav Grigorov</strong></h5>
                            <small className><strong>Templatemonster Customer</strong></small>
                        </div>
                        <p className="text pt-3 mx-auto">Excellent and well structured template, thanks lot!!!</p>
                    </div>
                </OwlCarousel>
                </div>
            </div>
            </div>
        </section>
        {/* End Testimonial Section */}
        </>
    )
}

export default TestimonialOne;