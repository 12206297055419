import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import ProjectMixItems from '../../data/ProjectMixItems';

const ProjectsMixOne = () => {

    const [items, setItems] = useState(ProjectMixItems);

    const filterItems = (categItem) => {
        const updatedItems = ProjectMixItems.filter((curElem) => {
            return curElem.categoty === categItem;
        });

        setItems(updatedItems);

    }

    return(
        <>
        {/*Portfolio Page Section*/}
        <section className="portfolio-section section-padding-all">
            <div className="default-container">
            <div className="row clearfix">
                <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                {/*Sec Title*/}
                <div className="sec-con-title text-center centered">
                    <div className="con-title-text con-title-border-l">It's Best Simple &amp; Useful</div>
                    <h2>What We Have Done?</h2>
                    <div className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
                </div>
                </div>
            </div>
            {/*MixitUp Galery*/}
            <div className="mixitup-gallery">
                {/*Filter*/}
                <div className="filters text-center clearfix">
                <ul className="filter-tabs filter-btns clearfix">
                    <li className="filter" onClick={() => setItems(ProjectMixItems)}>All Works</li>
                    <li className="filter" onClick={() => filterItems('seo')}>seo</li>
                    <li className="filter" onClick={() => filterItems('webdesign')}>webdesign</li>
                    <li className="filter" onClick={() => filterItems('work')}>work</li>
                    <li className="filter" onClick={() => filterItems('wordpress')}>wordpress</li>
                </ul>
                </div>
                <div className="filter-list row clearfix">

                    {
                        items.map((elem) => {
                            const {id, image} = elem;
                            
                            return (
                            <>
                            {/*Gallery Block Two*/}
                            <div className="gallery-block-two col-lg-4 col-md-6 col-sm-12">
                                <div className="inner-box">
                                <figure className="image-box">
                                    <img src={image} alt="project" />
                                    {/*Overlay Box*/}
                                    <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                        <Link to={'/single-project/'+id} className="link"><span className="icon fa fa-link" /></Link>
                                        </div>
                                    </div>
                                    </div>
                                </figure>
                                </div>
                            </div>
                            </>
                            )

                        })
                    }

                    
                </div>
            </div>
            </div>
        </section>
        {/*End Portfolio Page Section*/}
        </>
    )
}

export default ProjectsMixOne;