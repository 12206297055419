import React from 'react';
import { Link } from 'react-router-dom';
import AffiliData from '../../data/affili/AffiliData.json';

const AffiliProjects = () => {

    return(
        <>
        {/*Blog Section*/}
        <section className="affi-section section-padding-all">
            <div className="default-container">
                <div className="row clearfix">
                        <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                        {/*Sec Title*/}
                            <div className="sec-con-title text-left left mx-auto">
                                <div className="con-title-text con-title-border-l">It will help you to find out best one you</div>
                                <h2>Featured Products</h2>
                                <div className="text">All of them are the best, Just select which one you like to use</div>
                            </div>
                        </div>
                </div>
                <div className="row clearfix mt-3">
                    {AffiliData.slice( 0, 3 ).map(item => {
                    return (
                    <div className="col-lg-4 mb-4">
                        <div className="img_blog">
                            <div className="item">
                                <img src={`${item.image}`} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </div>
                        <div className="blog-box-detail p-4">
                            <div className="mt-0">
                                <h5 className="font-weight-bold"><a target={"_blank"} href={`${item.downloadlink}`} className="read-more">{item.title.substring(0, 30)+'...'}</a></h5>
                                <p className="text-muted">{item.details.substring(0, 70)+'...'}</p>
                                <ul className='pd-link'>
                                    <li><a target={"_blank"} className="corpo-r-btn btn-style-two sp-btn" href={`${item.previewlink}`}><i class="fa fa-eye"></i>Preview</a></li>
                                    <li><a target={"_blank"} className="corpo-r-btn btn-style-three sp-btn" href={`${item.downloadlink}`}><i class="fa fa-cloud-download"></i>Download</a></li>
                                </ul>
                                <span className='technology-icon'><img src={`images/icons/${item.technoicon}`} alt={item.title} className="tec-icon" /></span>
                            </div>
                        </div>
                    </div>
                    );
                    })}
                    <div className="con-title-column col-lg-12 col-md-12 col-sm-12 mt-3">
                        <div className="sec-con-title text-right right mx-auto">
                            <Link class="corpo-r-btn btn-style-two" to="/featured-products">View All Products</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*End Blog Section*/}
        </>
    )
}

export default AffiliProjects;