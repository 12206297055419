import React from "react";
import HireUsForm from "../../common/forms/HireUsForm";

const HireUs = () => {    

    return(
        <>
        {/*Contact Section*/}
        <section className="contact-section section-padding-all">
            <div className="default-container">
            <div className="row clearfix">
                <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                {/*Sec Title*/}
                <div className="sec-con-title text-center centered mx-auto">
                    <div className="con-title-text con-title-border-l">Send Your Message &amp; What you looking for?</div>
                    <h2>Welcome to Our Hire Us Programme</h2>
                    <div className="text">We are ready to help you as soon as we are able.</div>
                </div>
                </div>
            </div>
            <div className="row vertical-content-manage clearfix">
                <div className="col-lg-5">
                <div className="contact-info-box mt-3 animated">
                    <div className="contact-details-content p-3 mt-3">
                    <div className="contact-detail">
                        <h6 className="font-weight-bold">All the services that are provided</h6>
                        <ul className="text-muted mb-0 serv">
                            <li>Landing page creation</li>
                            <li>Bugs / issues Fixing</li>
                            <li>Restore and Migration</li>
                            <li>WordPress installation & Theme setup</li>
                            <li>UX/UI Design</li>
                            <li>Logo Creation</li>
                            <li>PSD to WordPress / HTML / React</li>
                            <li>WooCommerce Integration</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-7">
                    <div className="business_form_custom mt-3">
                        <HireUsForm />
                    </div>
                </div>
            </div>
            </div>
        </section>
        {/* End Contact Section */}
        </>
    )
}

export default HireUs;