import React from 'react';
import { Link } from 'react-router-dom';

const OurTeamOne = () => {
    return(
        <>
        {/*Team Section*/}
        <section className="teams-ection section-padding-all">
            <div className="default-container">
            <div className="row clearfix">
                <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                {/*Sec Title*/}
                <div className="sec-con-title text-center centered mx-auto">
                    <div className="con-title-text con-title-border-l">It's Best Simple &amp; Useful</div>
                    <h2>Our Team</h2>
                    <div className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
                </div>
                </div>
            </div>
            <div className="row clearfix mt-3">
                <div className="col-lg-3 mb-4">
                <div className="team-box-all text-center">
                    <div className="team-img">
                    <img src="images/team/tm-01-460x460.png" alt="" className="img-fluid mx-auto d-block" />
                    </div>
                    <div className="mt-3">
                    <h6 className="mb-0 font-weight-bold">Jeffery Newman</h6>
                    <p className="text-muted team-work">CEO</p>
                    </div>
                    <div className="team_social">
                    <ul className="list-inline mb-0 text-center">
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-facebook" /></Link></li>
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-google-plus" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-twitter" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-linkedin" /></Link></li>
                    </ul>
                    </div>
                </div>
                </div>
                <div className="col-lg-3 mb-4">
                <div className="team-box-all text-center">
                    <div className="team-img">
                    <img src="images/team/tm-02-460x460.png" alt="" className="img-fluid mx-auto d-block" />
                    </div>
                    <div className="mt-3">
                    <h6 className="mb-0 font-weight-bold">Christopher Mayo</h6>
                    <p className="text-muted team-work">Founder</p>
                    </div>
                    <div className="team_social">
                    <ul className="list-inline mb-0 text-center">
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-facebook" /></Link></li>
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-google-plus" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-twitter" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-linkedin" /></Link></li>
                    </ul>
                    </div>
                </div>
                </div>
                <div className="col-lg-3 mb-4">
                <div className="team-box-all text-center">
                    <div className="team-img">
                    <img src="images/team/tm-03-460x460.png" alt="" className="img-fluid mx-auto d-block" />
                    </div>
                    <div className="mt-3">
                    <h6 className="mb-0 font-weight-bold">Douglass Foster</h6>
                    <p className="text-muted team-work">Co-Founder</p>
                    </div>
                    <div className="team_social">
                    <ul className="list-inline mb-0 text-center">
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-facebook" /></Link></li>
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-google-plus" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-twitter" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-linkedin" /></Link></li>
                    </ul>
                    </div>
                </div>
                </div>
                <div className="col-lg-3 mb-4">
                <div className="team-box-all text-center">
                    <div className="team-img">
                    <img src="images/team/tm-04-460x460.png" alt="" className="img-fluid mx-auto d-block" />
                    </div>
                    <div className=" mt-3">
                    <h6 className="mb-0 font-weight-bold">Ismael Oliver</h6>
                    <p className="text-muted team-work">Sr. Developers</p>
                    </div>
                    <div className="team_social">
                    <ul className="list-inline mb-0 text-center">
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-facebook" /></Link></li>
                        <li className="list-inline-item"><Link to="#" className><i className="fa fa-google-plus" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-twitter" /></Link></li>
                        <li className="list-inline-item"><Link to="#"><i className="fa fa-linkedin" /></Link></li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/*End Team Section*/}
        </>
    )
}

export default OurTeamOne;