import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PostData from '../../data/blog/PostData.json';

const BlogPostSingleFullWidth = () => {

    const { id } = useParams();
    const blogId = parseInt(id, 10);
    const dataItem = PostData.filter(blog => blog.id === blogId);
    const item = dataItem[0];
    
    return(
        <>
        {/*Blog Details Section*/}
        <section className="single-blog-section section-padding-all">
            <div className="default-container">
            <div className="row">
                <div className="col-md-12">
                <div className="blog-detail mt-30">
                    <div className="b-det-img">
                    <img src={`${process.env.PUBLIC_URL}/images/blog/${item.imagelarge}`} alt="blog" />
                    </div>
                    <div className="det-content">
                    <h3>{item.title}</h3>
                    <div className="det-meta">
                        <Link to="#">
                        <i className="fa fa-user" /> by {item.admin}
                        </Link>
                        <Link to="#">
                        <i className="fa fa-calendar" /> {item.date}
                        </Link>
                        <Link to="#">
                        <i className="fa fa-commenting-o" /> {item.comment}
                        </Link>
                    </div>
                    <p>{item.details}</p>
                    </div>
                    <blockquote>
                    <p> “ {item.quote} ”
                    </p>
                    </blockquote>
                    <div className="det-list">
                    <div className="row">
                        <div className="col-sm-7">
                        <ul>
                            <li>
                            <p>{item.det1}</p>
                            </li>
                            <li>
                            <p>{item.det2}</p>
                            </li>
                            <li>
                            <p>{item.det3}</p>
                            </li>
                            <li>
                            <p>{item.det4}</p>
                            </li>
                            <li>
                            <p>{item.det5}</p>
                            </li>
                        </ul>
                        </div>
                        <div className="col-sm-5">
                        <img src={`${process.env.PUBLIC_URL}/images/blog/${item.innerimage}`} alt="blog" />
                        </div>
                    </div>
                    </div>
                    <div className="blog-tags">
                    <div className="row">
                        <div className="col-md-8">
                        <span className="title">Tags :</span>
                        { item.tags && item.tags.length > 0 && item.tags.map( ( tag, i ) => { return ( <Link className="tag" key={ i } to={process.env.PUBLIC_URL + `#`}>{ tag }</Link> ) } ) } 
                        </div>
                        <div className="col-md-4 text-right">
                        <span className="title">share :</span>
                        <Link to="#" className="blog-det-social">
                            <i className="fa fa-facebook" />
                        </Link>
                        <Link to="#" className="blog-det-social">
                            <i className="fa fa-twitter" />
                        </Link>
                        <Link to="#" className="blog-det-social">
                            <i className="fa fa-instagram" />
                        </Link>
                        <Link to="#" className="blog-det-social">
                            <i className="fa fa-google-plus" />
                        </Link>
                        </div>
                    </div>
                    </div>
                    <div className="blog-nav clearfix">
                    <Link to="#" className="blog-prev"><i className="fa fa-angle-left" /></Link>
                    <Link to="#" className="blog-next"><i className="fa fa-angle-right" /></Link>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/*End Blog Details Section */}
        </>
    )
}

export default BlogPostSingleFullWidth;