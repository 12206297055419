import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return(
        <>
        {/*Header-Upper*/}
        <div className="header-upper">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <div className="pull-left logo-box">
                <div className="logo">
                  <Link to="/"><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" title /></Link>
                </div>
              </div>
              <div className="nav-outer clearfix">
                {/* Main Menu */}
                <nav className="main-menu navbar-expand-md">
                  <div className="navbar-header">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="icon-bar" />
                      <i className="icon-bar" />
                      <i className="icon-bar" />
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                    <ul className="navigation clearfix">
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/products">Themes & Templates</Link></li>
                      <li><Link to="/featured-products">Featured Products</Link></li>
                      <li><Link to="/hire-us">Hire Us</Link></li>

                      {/* <li><Link to="/blog">Blog</Link></li> */}
                      <li><Link to="/contact-us">Contact us</Link></li>
                    </ul>
                  </div>
                </nav>
                {/*Button Box*/}
                <div className="button-box">
                  <a target={'_blank'} href="https://join.skype.com/invite/uRuDhr84ca8c" className="corpo-r-btn btn-style-one">Support 24/7</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
        {/*Sticky Header*/}
        <div className="sticky-header">
          <div className="container-fluid clearfix">
            {/*Logo*/}
            <div className="logo pull-left">
              <Link to="/" className="img-responsive"><img src={`${process.env.PUBLIC_URL}/images/logo-small.png`} alt="" title /></Link>
            </div>
            {/*Right Col*/}
            <div className="right-col pull-right">
              {/* Main Menu */}
              <nav className="main-menu navbar-expand-md">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </button>
                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent1">
                  <ul className="navigation clearfix">
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/products">Themes & Templates</Link></li>
                      <li><Link to="/featured-products">Featured Products</Link></li>
                      <li><Link to="/hire-us">Hire Us</Link></li>
                      {/* <li><Link to="/blog">Blog</Link></li> */}
                      <li><Link to="/contact-us">Contact us</Link></li>
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
            </div>
          </div>
        </div>
        {/*End Sticky Header*/}
        </>
    )
}

export default Nav;