import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ProjectData from '../../data/project/ProjectData.json';

const ProjectPostSingleFullWidth = () => {

    const { id } = useParams();
    const ProjectID = parseInt(id, 10);
    const dataItem = ProjectData.filter(project => project.id === ProjectID);
    const item = dataItem[0];
    
    return(
        <>
        {/* Projects Section*/}
        <section className="single-portfolio-section section-padding-all">
        <div className="default-container">
            <div className="row">
            <div className="col-md-12">
                <div className="project-thumb">
                <img src={`${process.env.PUBLIC_URL}/images/project/${item.imagesingle}`} alt="project" />
                </div>
            </div>
            <div className="col-md-8 det-content">
                <h3>{item.title}</h3>
                <p>{item.details}</p>
                <div className="portfolio-tags">
                <div className="row">
                    <div className="col-md-8">
                    <span className="title">Tags :</span>
                    { item.tags && item.tags.length > 0 && item.tags.map( ( tag, i ) => { return ( <Link className="tag" key={ i } to={process.env.PUBLIC_URL + `#`}>{ tag }</Link> ) } ) } 
                    </div>
                    <div className="col-md-4">
                    <span className="title">share :</span>
                    <Link to="#" className="portfolio-det-social">
                        <i className="fa fa-facebook" />
                    </Link>
                    <Link to="#" className="portfolio-det-social">
                        <i className="fa fa-twitter" />
                    </Link>
                    <Link to="#" className="portfolio-det-social">
                        <i className="fa fa-instagram" />
                    </Link>
                    <Link to="#" className="portfolio-det-social">
                        <i className="fa fa-google-plus" />
                    </Link>
                    </div>
                </div>
                </div>
                <div className="blog-nav clearfix">
                <Link to="#" className="blog-prev"><i className="fa fa-angle-left" /></Link>
                <Link to="#" className="blog-next"><i className="fa fa-angle-right" /></Link>
                </div>
            </div>
            <div className="col-md-4 mt-4">
                <div className="info">
                <ul>
                    <li>
                    Date <span>{item.date}</span>
                    </li>
                    <li>
                    client name <span>{item.clientname}</span>
                    </li>
                    <li>
                    Architect <span>{item.projectmanager}</span>
                    </li>
                    <li>
                    Budget <span>{item.budget}</span>
                    </li>
                    <li>
                    rating
                    <Link to="#"><i className="fa fa-star" /></Link>
                    <Link to="#"><i className="fa fa-star" /></Link>
                    <Link to="#"><i className="fa fa-star" /></Link>
                    <Link to="#"><i className="fa fa-star" /></Link>
                    <Link to="#"><i className="fa fa-star-half-o" /></Link>
                    </li>
                </ul>
                </div>
            </div>
            </div></div></section>
        {/* End Projects Section*/}
        </>
    )
}

export default ProjectPostSingleFullWidth;