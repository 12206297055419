import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostsLeftSidebar from '../../components/blog-components/BlogPostsLeftSidebar';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const BlogPostsThree = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Blog Left Sidebar"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Left Sidebar"
        />
        
        <BlogPostsLeftSidebar />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default BlogPostsThree;