import React from 'react';
import { Link } from 'react-router-dom';
import ProjectData from '../../data/project/ProjectData.json';

let pronum = Math.floor(Math.random() * 12);

const BannerTwo = () => {
    return(
        <>
        {/*Banner Section*/}
        <section id="hero" className="hero-section">
            <div className="hero-bg"> 
            <img alt="" src={`images/hero-bg-new.svg`} className=" br-loaded" />
            </div>
            <div className="default-container">
                <div className="row align-items-center">
                    <div className="col-lg-6 mt-50">
                        <div className="hero-content-wrapper">
                            <h2 className="mb-30">HTML Landing Page&nbsp;<br></br><span>Templates, WordPress Themes and</span> <br></br>React Templates</h2>
                            <p className="mb-50"> 
                            Free and Premium HTML Landing Page Templates, Bootstrap Templates, React Templates and WordPress Themes to Create and Launch Complete Website Beautiful Design Without Coding From Scratch.
                            </p>
                            <div className="hero-action"> 
                            <Link to={`/products`} className="corpo-r-btn btn-style-two">All Products</Link>
                            </div>
                        </div>
                    </div>
                    { 
                    ProjectData.slice( pronum, pronum+1 ).map((item) => (
                    <div className="col-lg-6  mt-30">
                        <div className="img_blog">
                            <div className="item">
                                <img src={`${item.image}`} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </div>
                        <div className="blog-box-detail p-4">
                            <div className="mt-0">
                                <h5 className="font-weight-bold"><Link to={`${item.downloadlink}`} className="read-more">{item.title.substring(0, 35)+'...'}</Link></h5>
                                <p className="text-muted">{item.details.substring(0, 70)+'...'}</p>
                                <ul className='pd-link'>
                                    <li><a target={"_blank"} className="corpo-r-btn btn-style-two sp-btn" href={`${item.previewlink}`}><i class="fa fa-eye"></i>Preview</a></li>
                                    <li><a target={"_blank"} className="corpo-r-btn btn-style-three sp-btn" href={`${item.downloadlink}`}><i class="fa fa-cloud-download"></i>Download</a></li>
                                </ul>
                                <span className='technology-icon'><img src={`images/icons/${item.technoicon}`} alt={item.title} className="tec-icon" /></span>
                            </div>
                        </div>
                    </div>
                    ))
                    }
                </div>
            </div>
        </section>
        {/*End Banner Section*/}
        </>
    )
}

export default BannerTwo;