import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import HireUs from '../../components/inner-components/HireUs';
import TestimonialOne from '../../components/section-components/TestimonialOne';
import FooterTwo from '../../common/footer/FooterTwo';

const Hire = () => {

    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Hire Us"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Hire Us"
        />
        
        <HireUs />
        
        <TestimonialOne />
                
        <FooterTwo />
        </>
    )
}
    
export default Hire;