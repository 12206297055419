import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostsTwoCol from '../../components/blog-components/BlogPostsTwoCol';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const BlogPostsTwo = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Blog Two Column"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Two Column"
        />
        
        <BlogPostsTwoCol />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default BlogPostsTwo;