import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutOne from '../../components/section-components/AboutOne';
import ProjectsMixOne from '../../components/section-components/ProjectsMixOne';
import TestimonialOne from '../../components/section-components/TestimonialOne';
import Footer from '../../common/footer/Footer';

const AboutUs = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="About Us"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="About Us"
        />
        
        <AboutOne />
        
        <ProjectsMixOne />
        
        <TestimonialOne />
        
        <Footer />
        </>
    )
}
    
export default AboutUs;