import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostsRightSidebar from '../../components/blog-components/BlogPostsRightSidebar';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const BlogPostsFour = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Blog Right Sidebar"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Right Sidebar"
        />
        
        <BlogPostsRightSidebar />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default BlogPostsFour;