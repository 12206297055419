import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostSingleRightSidebar from '../../components/blog-details-components/BlogPostSingleRightSidebar';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const BlogDetailsThree = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Blog Details Right Sidebar"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Details Right Sidebar"
        />
        
        <BlogPostSingleRightSidebar />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default BlogDetailsThree;