import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeOne from './pages/homepages/HomeOne';
import AboutUs from './pages/innerpages/AboutUs';
import Team from './pages/innerpages/Team';
import Contact from './pages/innerpages/Contact';
import Price from './pages/innerpages/Price';
import BlogPostsOne from './pages/blogpages/BlogPostsOne';
import BlogPostsTwo from './pages/blogpages/BlogPostsTwo';
import BlogPostsThree from './pages/blogpages/BlogPostsThree';
import BlogPostsFour from './pages/blogpages/BlogPostsFour';
import BlogDetailsOne from './pages/blogdetailspages/BlogDetailsOne';
import BlogDetailsTwo from './pages/blogdetailspages/BlogDetailsTwo';
import BlogDetailsThree from './pages/blogdetailspages/BlogDetailsThree';
import ProjectPostsOne from './pages/projectpages/ProjectPostsOne';
import ProjectPostsTwo from './pages/projectpages/ProjectPostsTwo';
import ProjectDetailsOne from './pages/projectdetailspages/ProjectDetailsOne';
import AllProducts from './pages/innerpages/AllProducts';
import AffiAllProducts from './pages/innerpages/AffiAllProducts';
import Hire from './pages/innerpages/Hire';
import Error from './pages/innerpages/Error';

function App() {
  return (
    <Router>
        <Routes>
          <Route exact path='/' element={<HomeOne/>}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/about-us'}`} element={<AboutUs />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/team'}`} element={<Team />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<Contact />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/price'}`} element={<Price />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/hire-us'}`} element={<Hire />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/blog'}`} element={<BlogPostsOne />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/products'}`} element={<AllProducts />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/featured-products'}`} element={<AffiAllProducts />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/blog-posts-fullwidth-two-column'}`} element={<BlogPostsTwo />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/blog-posts-left-sidebar'}`} element={<BlogPostsThree />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/blog-posts-right-sidebar'}`} element={<BlogPostsFour />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/single-post/:id'}`} element={<BlogDetailsOne />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/single-post-left-sidebar/:id'}`} element={<BlogDetailsTwo />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/single-post-right-sidebar/:id'}`} element={<BlogDetailsThree />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/projects-fullwidth-three-column'}`} element={<ProjectPostsOne />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/projects-fullwidth-two-column'}`} element={<ProjectPostsTwo />}/>
          <Route exact path={`${process.env.PUBLIC_URL + '/single-project/:id'}`} element={<ProjectDetailsOne />}/>
          <Route path='*' element={<Error/>}/>
        </Routes>
    </Router>
  );
}

export default App;
