import React from 'react';
import { Link } from 'react-router-dom';
import PostData from '../../data/blog/PostData.json';

const BlogPostsOne = () => {
    
    return(
        <>
        {/*Blog Section*/}
        <section className="blog-section section-padding-all">
            <div className="default-container">
            <div className="row clearfix">

            { 
                PostData.slice( 0, 12 ).map((item) => (
                <div className="col-lg-4 mb-4">
                    <div className="img_blog">
                        <div className="carousel-item active">
                            <img src={`${process.env.PUBLIC_URL}/images/blog/${item.image}`} alt="" className="img-fluid mx-auto d-block" />
                        </div>
                    </div>
                    <div className="blog-box-detail p-4">
                        <div className="mt-0">
                            <p className="labal text-muted">{item.categories.slice(0, 1)}</p>
                            <h5 className="font-weight-bold"><Link to={`/single-post/${item.id}`} className="read-more">{item.title}</Link></h5>
                            <p className="text-muted">{item.details.substring(0, 100)}</p>
                            <p className="space-date text-muted"><strong>{item.admin} - {item.date}</strong></p>
                            <Link className="read-more text-uppercase font-weight-bold" to={`/single-post/${item.id}`}>Read More</Link>
                        </div>
                    </div>
                </div>
                ))
            }
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ul className="cr-pagination-center">
                    <li><Link to="#" title><i className="fa fa-angle-left" /></Link></li>
                    <li className="active"><Link to="#" title>1</Link></li>
                    <li><Link to="#" title>2</Link></li>
                    <li><Link to="#" title>3</Link></li>
                    <li><Link to="#" title><i className="fa fa-angle-right" /></Link></li>
                </ul>
                </div>
            </div>
            </div>
        </section>
        {/*End Blog Section*/}
        </>
    )
}

export default BlogPostsOne;