import React from 'react';

const CopyrightTwo = () => {
    return(
        <>
        <div className="footer-copyright">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <div className="copyright text-center">
                    <p className="text">Copyright © 2024 DevTheme. All Rights Reserved</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CopyrightTwo;