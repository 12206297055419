import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostsFullWidth from '../../components/blog-components/BlogPostsFullWidth';
//import GetStart from '../../components/section-components/GetStart';
import FooterTwo from '../../common/footer/FooterTwo';

const BlogPostsOne = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne
            title="Blog Full Width"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Full Width"
        />

        <BlogPostsFullWidth />

        {/* <GetStart /> */}

        <FooterTwo />
        </>
    )
}

export default BlogPostsOne;