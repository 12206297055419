import React , { useState } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Thanks for your query. We will contact with you soon.</p>
    )
}

function HireUsForm( { formStyle } ) {
    const [result, setResult] = useState( false );

    const sendEmail = ( e ) => {
        e.preventDefault();
        emailjs.sendForm('service_2umo8nk', 'template_v8w7sgb', e.target, 'loHxiUVzJOjB1f0k9' )
        .then( ( result ) => {
            console.log( result.text );
            }, 
            ( error ) => {
                console.log( error.text );
            }
        );
        e.target.reset();
        setResult( true );
    };

    setTimeout(() => {
        setResult( false );
    }, 5000);

    return (
        <form className={`${ formStyle }`} onSubmit={sendEmail}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <input name="user_name" id="user_name" type="text" className="form-control" placeholder="Your Name" required />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input name="user_email" id="user_email" type="email" className="form-control" placeholder="Your Email" required />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" name="user_subject" className="form-control" id="user_subject" placeholder="Job Title" required />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" name="user_budget" className="form-control" id="user_budget" placeholder="Budget" required />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <textarea name="message" id="message" rows={4} className="form-control" placeholder="Your message..." required defaultValue={""} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className=" form-group">
                        <input type="submit" className="btn btn_custom corpo-r-btn btn-style-two" defaultValue="Send Message" />
                    </div>
                    { result ? <div className="form-group"><Result /></div>  : null }
                </div>
            </div>  
        </form>
    )
}
export default HireUsForm;