import React from 'react';
import { Link } from 'react-router-dom';
import PostData from '../../data/blog/PostData.json';

const SideBar = () => {
    
    return(
        <>
        <div className="widget widget-search">
            <form className="side-form">
            <input type="text" className="form-control" placeholder="Search Here..." />
            <button type="submit">
                <i className="fa fa-paper-plane" />
            </button>
            </form>
        </div>
        <div className="widget widget-categories">
            <div className="all-title">
            <h3>
                <span>Categories</span>
            </h3>
            </div>
            <div className="widget-items">
            <ul>
                <li className="blog-category">
                <Link to="#">Online Marketing
                    <span>(23)</span>
                </Link>
                </li>
                <li className="blog-category">
                <Link to="#">Web Development
                    <span>(26)</span>
                </Link>
                </li>
                <li className="blog-category">
                <Link to="#">Graphic Design
                    <span>(13)</span>
                </Link>
                </li>
                <li className="blog-category">
                <Link to="#">Brand Marketing
                    <span>(18)</span>
                </Link>
                </li>
                <li className="blog-category">
                <Link to="#">Networking
                    <span>(28)</span>
                </Link>
                </li>
            </ul>
            </div>
        </div>
        <div className="widget widget-recent-post">
            <div className="all-title">
            <h3>
                <span>Recent Post</span>
            </h3>
            </div>
            <div className="widget-items">
            <ul>
            { 
                PostData.slice( 0, 5 ).map((item) => (
                <li>
                <div className="blog-rp-image">
                    <Link to={`/single-post-fullwidth/${item.id}`}>
                    <img src={`${process.env.PUBLIC_URL}/images/blog/${item.imagesmall}`} alt="thumb" />
                    </Link>
                </div>
                <div className="blog-rp-info">
                    <p><Link to={`/single-post-fullwidth/${item.id}`}>{item.title}</Link></p>
                    <p>{item.details.substring(0, 25)}</p>
                </div>
                </li>
                ))
            }
            </ul>
            </div>
        </div>
        <div className="widget widget-archives">
            <div className="all-title">
            <h3>
                <span>archives</span>
            </h3>
            </div>
            <div className="widget-items widget-archie">
            <ul>
                <li>
                <Link to="#">july
                    <span>20</span>
                </Link>
                </li>
                <li>
                <Link to="#">November
                    <span>36</span>
                </Link>
                </li>
                <li>
                <Link to="#">october
                    <span>12</span>
                </Link>
                </li>
            </ul>
            </div>
        </div>
        <div className="widget widget-tags">
            <div className="all-title">
            <h3>
                <span>Popular Tags</span>
            </h3>
            </div>
            <div className="widget-tags widget-items">
            <Link to="#" className="tag">marketing</Link>
            <Link to="#" className="tag">Design</Link>
            <Link to="#" className="tag">Networking</Link>
            <Link to="#" className="tag">Branding</Link>
            <Link to="#" className="tag">Wordpress</Link>
            <Link to="#" className="tag">Website</Link>
            <Link to="#" className="tag">Graphic Design</Link>
            <Link to="#" className="tag">Fonts</Link>
            </div>
        </div>
        <div className="widget widget-meta">
            <div className="all-title">
            <h3>
                <span>meta</span>
            </h3>
            </div>
            <div className="widget-items widget-meta">
            <Link to="#">Entries.RSS</Link>
            <Link to="#">Comment.RSS</Link>
            <Link to="#">Wordpress.org</Link>
            <Link to="#">Site Admin</Link>
            </div>
        </div>
        </>
    )
}

export default SideBar;