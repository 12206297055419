import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostSingleLeftSidebar from '../../components/blog-details-components/BlogPostSingleLeftSidebar';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const BlogDetailsTwo = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Blog Details Left Sidebar"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Details Left Sidebar"
        />
        
        <BlogPostSingleLeftSidebar />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default BlogDetailsTwo;