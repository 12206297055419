import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AffiAllProjectsPage from '../../components/inner-components/AffiAllProjectsPage';
import CompanySuccess from '../../components/section-components/CompanySuccess';
import FooterTwo from '../../common/footer/FooterTwo';

const AffiAllProducts = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Featured Products"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Featured Products"
        />
        
        <AffiAllProjectsPage />
        
        <CompanySuccess />
        
        <FooterTwo />
        </>
    )
}
    
export default AffiAllProducts;