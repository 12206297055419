import React, { useRef } from 'react';
import ContactUsForm from '../../common/forms/ContactUsForm';

const ContactUs = () => {   

    return(
        <>
        {/*Contact Section*/}
        <section className="contact-section section-padding-all">
            <div className="default-container">
            <div className="row clearfix">
                <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                {/*Sec Title*/}
                <div className="sec-con-title text-center centered mx-auto">
                    <div className="con-title-text con-title-border-l">Send Us What is in your &amp; mind?</div>
                    <h2>Contact Us for any Query</h2>
                    <div className="text">We will be with you as soon as we are able.</div>
                </div>
                </div>
            </div>
            <div className="row vertical-content-manage clearfix">
                <div className="col-lg-5">
                <div className="contact-info-box mt-3 animated">
                    <div className="contact-details-content p-3 mt-3">
                    <div className="contact-detail">
                        <h6 className="font-weight-bold">Address</h6>
                        <p className="text-muted mb-0">Arafatnogor, C. O. Colony,  Joypurhat, 5900
                        <br /> Dhaka, Bangladesh</p>
                    </div>
                    </div>
                    <div className="contact-details-content p-3 mt-3">
                    <div className="contact-detail">
                        <h6 className="font-weight-bold">Mail</h6>
                        <p className="text-muted mb-0">support@devtheme.net</p>
                        <p className="text-muted mb-0">info@devtheme.net</p>
                    </div>
                    </div>
                    <div className="contact-details-content p-3 mt-3">
                    <div className="contact-detail">
                        <h6 className="font-weight-bold">Call</h6>
                        <p className="text-muted mb-0">+880 1726 903 999,
                        <br />+880 1864 893 775</p>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-7">
                    <div className="business_form_custom mt-3">
                        <ContactUsForm />
                    </div>
                </div>
            </div>
            </div>
        </section>
        {/* End Contact Section */}
        </>
    )
}

export default ContactUs;