import React from 'react';
import { Link } from 'react-router-dom';
import CopyrightTwo from './CopyrightTwo';

const FooterTwo = () => {
    return(
        <>
        {/*Main Footer*/}

        <footer className="footer-five">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="footer-top-content">
                            <div className="footer-logo text-center">
                                <Link to="/">
                                    <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" />
                                </Link>
                            </div>
                            <p className="text-center">We are DevTheme, We develop WordPress Themes, Plugins, HTML Templates, React Templates PSDs. Thanks</p>
                            <h5 className="text-center social-title">Follow Us On</h5>
                            <ul className="social text-center mt-60">
                            {/* <li>
                                <a href="https://www.facebook.com/ThemeHurst.me/"> <i class="fa fa-facebook"></i> </a>
                            </li> */}
                            {/* <li>
                                <a href="https://twitter.com/themehurst/"> <i class="fa fa-twitter"></i> </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/themehurst/"> <i class="fa fa-instagram"></i> </a>
                            </li> */}
                            <li>
                                <a href="https://www.linkedin.com/showcase/devtheme"> <i class="fa fa-linkedin-square"></i> </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <CopyrightTwo />
        </footer>
        {/*End Main Footer*/}
        </>
    )
}

export default FooterTwo;