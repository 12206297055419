import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AllTeamMembers from '../../components/inner-components/AllTeamMembers';
import CompanySuccess from '../../components/section-components/CompanySuccess';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const AboutUs = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Team"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Team"
        />
        
        <AllTeamMembers />
        
        <CompanySuccess />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default AboutUs;