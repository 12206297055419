import React from 'react';

const Features = () => {
    return(
        <>
        <section className="feature-section section-padding-all">
            <div className="default-container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-10">
                <div className="single-feature-2">
                    <div className="feature-icon primary" style={{background: '#0000c1'}}> 
                    <span className="icon"> 
                        <img alt="small feature" src={`images/icons/code.png`} className=" br-loaded" /> 
                    </span>
                    </div>
                    <div className="feature-content">
                    <h3>HTML Templates</h3>
                    <p> Free and Premium HTML Templates for SaaS, Startup, Business, Agency, App Landing, Software, Product and So on.</p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-10">
                    <div className="single-feature-2">
                        <div className="feature-icon primary" style={{background: '#20232a'}}> 
                            <span className="icon"> 
                                <img alt="small feature" src={`images/icons/react-2.png`} className=" br-loaded" /> 
                            </span>
                        </div>
                        <div className="feature-content">
                            <h3>React Templates</h3>
                            <p> Soon You Will See 20+ Free and Premium React JS Templates. We Just Started Crafting ReactJS Templates Just Recently, Yet to Release</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-10">
                <div className="single-feature-2">
                    <div className="feature-icon primary" style={{background: '#6610f2'}}> 
                    <span className="icon"> 
                        <img alt="small feature" src={`images/icons/bootstrap.png`} className=" br-loaded" /> 
                    </span>
                    </div>
                    <div className="feature-content">
                    <h3>Bootstrap 5 Themes</h3>
                    <p> Free Bootstrap Themes for Business, Portfolio, CV and Multipurpose Sites. Comes with Refreshing Design and Responsive Layout.</p>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 col-md-10">
                    <div className="single-feature-2">
                        <div className="feature-icon primary" style={{background: '#355fc1'}}> 
                            <span className="icon"> 
                                <img alt="small feature" src={`images/icons/wordpress.png`} className=" br-loaded" /> 
                            </span>
                        </div>
                        <div className="feature-content">
                            <h3>WordPress Themes</h3>
                            <p> We develop mordern WordPress themes. These themes are widely used and feature rich. Very Soon you will see more new and up-to-date featur themes on our website.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        </>
    )
}

export default Features;