import React , { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../setup/Pagination';
import ProjectData from '../../data/project/ProjectData.json';


let PageSize = 6;

const ProjectPostFullWidthThreeCol = () => {

    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return ProjectData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);
    
    return(
        <>
        {/*Projects Section*/}
        <section className="portfolio-section section-padding-all">
            <div className="default-container">
                <div className="mixitup-gallery">
                    <div className="filter-list row clearfix">
                    {/*Gallery Block Two*/}
                    {currentTableData.map(item => {
                        return (
                        <div className="gallery-block-two col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <img src={`${process.env.PUBLIC_URL}/images/gallery/${item.image}`} alt="project" />
                                    {/*Overlay Box*/}
                                    <div className="overlay-box">
                                        <div className="overlay-inner">
                                                <div className="content">
                                                    <Link to={`/single-project/${item.id}`} className="link"><span className="icon fa fa-link" /></Link>
                                                </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                        );
                    })}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={ProjectData.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>               
                    </div>
                </div>
                
            </div>
        </section>
        {/*End Projects Section*/}
        </>
    )
}

export default ProjectPostFullWidthThreeCol;