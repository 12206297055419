import React from 'react';
//import Top from './Top';
import Nav from './Nav';

const HeaderOne = () => {
    return(
        <>
        <header className="corpo-header style-two">
                      
            <Nav />
        
        </header>
        </>
    )
}

export default HeaderOne;