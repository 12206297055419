import React from 'react';
import { Link } from 'react-router-dom';
import ProjectData from '../../data/project/ProjectData.json';

const ProjectPostFullWidthTwoCol = () => {
    
    return(
        <>
        {/*Projects Section*/}
        <section className="portfolio-section section-padding-all">
            <div className="default-container">
                <div className="mixitup-gallery">
                    <div className="filter-list row clearfix">
                    {/*Gallery Block Two*/}
                        { 
                        ProjectData.slice( 0, 6 ).map((item) => (
                        <div className="gallery-block-two col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <img src={`${process.env.PUBLIC_URL}/images/gallery/${item.image}`} alt="project" />
                                    {/*Overlay Box*/}
                                    <div className="overlay-box">
                                        <div className="overlay-inner">
                                                <div className="content">
                                                    <Link to={`/single-project/${item.id}`} className="link"><span className="icon fa fa-link" /></Link>
                                                </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                        ))
                        }
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ul className="cr-pagination-center">
                                <li><Link to="#" title><i className="fa fa-angle-left" /></Link></li>
                                <li className="active"><Link to="#" title>1</Link></li>
                                <li><Link to="#" title>2</Link></li>
                                <li><Link to="#" title>3</Link></li>
                                <li><Link to="#" title><i className="fa fa-angle-right" /></Link></li>
                            </ul>
                        </div>               
                    </div>
                </div>
            </div>
        </section>
        {/*End Projects Section*/}
        </>
    )
}

export default ProjectPostFullWidthTwoCol;