const ProjectMixItems = [
{
    id: 1,
    image: "images/gallery/gl-01-750x650.png",
    categoty: "wordpress",
},
{
    id: 2,
    image: "images/gallery/gl-02-750x650.png",
    categoty: "seo",
},
{
    id: 3,
    image: "images/gallery/gl-03-750x650.png",
    categoty: "webdesign",
},
{
    id: 4,
    image: "images/gallery/gl-04-750x650.png",
    categoty: "wordpress",
},
{
    id: 5,
    image: "images/gallery/gl-05-750x650.png",
    categoty: "work",
},
{
    id: 6,
    image: "images/gallery/gl-06-750x650.png",
    categoty: "wordpress",
},
{
    id: 7,
    image: "images/gallery/gl-07-750x650.png",
    categoty: "webdesign",
},
{
    id: 8,
    image: "images/gallery/gl-08-750x650.png",
    categoty: "seo",
},
{
    id: 9,
    image: "images/gallery/gl-09-750x650.png",
    categoty: "work",
},
];

export default ProjectMixItems;