import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
//import Banner from '../../components/section-components/Banner';
import BannerTwo from '../../components/section-components/BannerTwo';
//import ServicesOne from '../../components/section-components/ServicesOne';
//import AboutOne from '../../components/section-components/AboutOne';
//import ProjectsMixOne from '../../components/section-components/ProjectsMixOne';
import ProjectHome from '../../components/section-components/ProjectHome';
import AffiliProjects from '../../components/section-components/AffiliProjects';
import TestimonialOne from '../../components/section-components/TestimonialOne';
//import PricingPlan from '../../components/section-components/PricingPlan';
//import OurTeamOne from '../../components/section-components/OurTeamOne';
import CompanySuccess from '../../components/section-components/CompanySuccess';
//import LatestNews from '../../components/section-components/LatestNews';
import Features from '../../components/section-components/Features';
//import GetStart from '../../components/section-components/GetStart';
import FooterTwo from '../../common/footer/FooterTwo';


const HomeOne = () => {
    return (
        <>
        <HeaderOne />

        {/* <Banner /> */}

        <BannerTwo />

        {/* <ServicesOne /> */}

        {/* <AboutOne /> */}

        {/* <ProjectsMixOne /> */}
        
        <ProjectHome />

        <AffiliProjects />

        {/* <PricingPlan /> */}

        {/* <OurTeamOne /> */}

        <CompanySuccess />

        {/* <LatestNews /> */}
        
        <Features />

        <TestimonialOne />

        {/* <GetStart /> */}

        <FooterTwo />
        </>
    )
}

export default HomeOne;