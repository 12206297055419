import React from 'react';
import CountUp from 'react-countup';

const CompanySuccess = () => {
    return(
        <>
        {/*Counter Section*/}
        <section className="counter-section section-padding-all">
            <div className="com-counter default-container">
            <div className="row clearfix">
                <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                {/*Column*/}
                <div className="row clearfix">
                    <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                    <div className="inner">
                        <div className="title-text count-title align-middle">
                        <h2><strong>Our valuable</strong> Success</h2>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*Column*/}
                <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                <div className="row clearfix">
                    <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                    <div className="inner">
                        <div className="content">
                        <div className="count-outer count-box">
                            <span className="count-text"><CountUp start={0} end={577}></CountUp></span>+
                        </div>
                        <h4 className="counter-title">happy customers</h4>
                        </div>
                    </div>
                    </div>
                    {/*Column*/}
                    <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                    <div className="inner">
                        <div className="content">
                        <div className="count-outer count-box alternate">
                            <span className="count-text"><CountUp start={0} end={48}></CountUp></span>+
                        </div>
                        <h4 className="counter-title">Products</h4>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                    <div className="inner">
                        <div className="content">
                        <div className="count-outer count-box alternate">
                            <span className="count-text"><CountUp start={0} end={1944}></CountUp></span>+
                        </div>
                        <h4 className="counter-title">Total Projects Done</h4>
                        </div>
                    </div>
                    </div>
                    {/*Column*/}
                    <div className="column counter-column col-md-6 col-sm-6 col-xs-12">
                    <div className="inner">
                        <div className="content">
                        <div className="count-outer count-box">
                            <span className="count-text"><CountUp start={0} end={23}></CountUp></span>+
                        </div>
                        <h4 className="counter-title">Running Projects</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/*End Counter Section*/}
        </>
    )
}

export default CompanySuccess;