import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ProjectPostFullWidthThreeCol from '../../components/project-components/ProjectPostFullWidthThreeCol';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const ProjectPostsOne = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Projects Full Width"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Projects Full Width"
        />
        
        <ProjectPostFullWidthThreeCol />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default ProjectPostsOne;