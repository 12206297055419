import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ProjectPostSingleFullWidth from '../../components/project-details-components/ProjectPostSingleFullWidth';
import GetStart from '../../components/section-components/GetStart';
import Footer from '../../common/footer/Footer';

const ProjectDetailsOne = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Project Details"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Project Details"
        />
        
        <ProjectPostSingleFullWidth />
        
        <GetStart />
        
        <Footer />
        </>
    )
}
    
export default ProjectDetailsOne;