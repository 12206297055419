import React from 'react';
import { Link, useParams } from 'react-router-dom';
import SideBar from '../../common/sidebar/SideBar';
import PostData from '../../data/blog/PostData.json';

const BlogPostSingleLeftSidebar = () => {

    const { id } = useParams();
    const blogId = parseInt(id, 10);
    const dataItem = PostData.filter(blog => blog.id === blogId);
    const item = dataItem[0];
    
    return(
        <>
        {/*Blog Details Section*/}
        <section className="single-blog-section section-padding-all">
            <div className="default-container">
                <div className="row">
                    <div className="col-md-8 order-md-2">
                        <div className="blog-detail mt-30">
                            <div className="b-det-img">
                            <img src={`${process.env.PUBLIC_URL}/images/blog/${item.imagelarge}`} alt="blog" />
                            </div>
                            <div className="det-content">
                            <h3>{item.title}</h3>
                            <div className="det-meta">
                                <Link to="#">
                                <i className="fa fa-user" /> by {item.admin}
                                </Link>
                                <Link to="#">
                                <i className="fa fa-calendar" /> {item.date}
                                </Link>
                                <Link to="#">
                                <i className="fa fa-commenting-o" /> {item.comment}
                                </Link>
                            </div>
                            <p>{item.details}</p>
                            </div>
                            <blockquote>
                            <p> “ {item.quote} ”
                            </p>
                            </blockquote>
                            <div className="det-list">
                            <div className="row">
                                <div className="col-sm-7">
                                <ul>
                                    <li>
                                    <p>{item.det1}</p>
                                    </li>
                                    <li>
                                    <p>{item.det2}</p>
                                    </li>
                                    <li>
                                    <p>{item.det3}</p>
                                    </li>
                                    <li>
                                    <p>{item.det4}</p>
                                    </li>
                                    <li>
                                    <p>{item.det5}</p>
                                    </li>
                                </ul>
                                </div>
                                <div className="col-sm-5">
                                <img src={`${process.env.PUBLIC_URL}/images/blog/${item.innerimage}`} alt="blog" />
                                </div>
                            </div>
                            </div>
                            <div className="blog-tags">
                            <div className="row">
                                <div className="col-md-8">
                                <span className="title">Tags :</span>
                                { item.tags && item.tags.length > 0 && item.tags.map( ( tag, i ) => { return ( <Link className="tag" key={ i } to={process.env.PUBLIC_URL + `#`}>{ tag }</Link> ) } ) } 
                                </div>
                                <div className="col-md-4">
                                <span className="title">share :</span>
                                <Link to="#" className="blog-det-social">
                                    <i className="fa fa-facebook" />
                                </Link>
                                <Link to="#" className="blog-det-social">
                                    <i className="fa fa-twitter" />
                                </Link>
                                <Link to="#" className="blog-det-social">
                                    <i className="fa fa-instagram" />
                                </Link>
                                <Link to="#" className="blog-det-social">
                                    <i className="fa fa-google-plus" />
                                </Link>
                                </div>
                            </div>
                            </div>
                            <div className="blog-nav clearfix">
                            <Link to="#" className="blog-prev"><i className="fa fa-angle-left" /></Link>
                            <Link to="#" className="blog-next"><i className="fa fa-angle-right" /></Link>
                            </div>
                            <div className="author-section">
                            <div className="all-title">
                                <h3 className="sec-title">
                                <span>About Author</span>
                                </h3>
                            </div>
                            <div className="author-post">
                                <div className="aut-img">
                                <img src={`${process.env.PUBLIC_URL}/images/team/tm-01-460x460.png`} alt="woman" />
                                </div>
                                <div className="aut-content">
                                <h5>Alec Thompson</h5>
                                <p>Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhon cus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis vel, aliquet nec pretium.</p>
                                <div className="aut-social">
                                    <Link to="#">
                                    <i className="fa fa-facebook" />
                                    </Link>
                                    <Link to="#">
                                    <i className="fa fa-twitter" />
                                    </Link>
                                    <Link to="#">
                                    <i className="fa fa-instagram" />
                                    </Link>
                                    <Link to="#">
                                    <i className="fa fa-google-plus" />
                                    </Link>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="comment-section">
                            <div className="all-title">
                                <h3 className="sec-title">
                                <span>comments</span>
                                </h3>
                            </div>
                            <div className="comment-wrap">
                                <div className="comment-inner">
                                <Link to="#" className="reply">
                                    <i className="fa fa-mail-reply-all"> </i>Reply
                                </Link>
                                <div className="com-img">
                                    <img src={`${process.env.PUBLIC_URL}/images/team/tm-02-460x460.png`} alt="human" />
                                </div>
                                <div className="comm-content">
                                    <h5>Alec Thompson
                                    <span>March 04, 2018 at 10:05 PM</span>
                                    </h5>
                                    <p>Donec pede justo, aliquet nec, vulputate eget, arcu. In enim justo, ahon cus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis vel.
                                    </p>
                                </div>
                                </div>
                                <div className="sub-comm comment-inner">
                                <Link to="#" className="reply">
                                    <i className="fa fa-mail-reply-all"> </i>Reply
                                </Link>
                                <div className="com-img">
                                    <img src={`${process.env.PUBLIC_URL}/images/team/tm-03-460x460.png`} alt="human" />
                                </div>
                                <div className="comm-content">
                                    <h5>Alec Thompson
                                    <span>March 04, 2018 at 10:05 PM</span>
                                    </h5>
                                    <p>Donec pede justo, aliquet nec, vulputate eget, arcu. In enim justo, ahon cus ut, imperdiet a, venenatis vitae, justol.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="comment-wrap">
                                <div className="comment-inner">
                                <Link to="#" className="reply">
                                    <i className="fa fa-mail-reply-all"> </i>Reply
                                </Link>
                                <div className="com-img">
                                    <img src={`${process.env.PUBLIC_URL}/images/team/tm-04-460x460.png`} alt="human" />
                                </div>
                                <div className="comm-content">
                                    <h5>Alec Thompson
                                    <span>March 04, 2018 at 10:05 PM</span>
                                    </h5>
                                    <p>Donec pede justo, aliquet nec, vulputate eget, arcu. In enim justo, ahon cus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis vel.
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="blog-comment-form">
                            <div className="all-title">
                                <h3 className="sec-title">
                                <span>leave comment</span>
                                </h3>
                            </div>
                            <form action="#" className="comment-form">
                                <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <input type="text" className="form-control" placeholder="name" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <input type="email" className="form-control" placeholder="email" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <textarea name="message" placeholder="Your Message" className="form-control" rows={6} defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <button type="submit" className="btn btn_custom corpo-r-btn btn-style-two">post comment</button>
                                    </div>
                                </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 order-md-1">
                        <SideBar />
                    </div>
                </div>
            </div>
        </section>
        {/*End Blog Details Section */}
        </>
    )
}

export default BlogPostSingleLeftSidebar;