import React from 'react';
import { Link } from 'react-router-dom';

const Copyright = () => {
    return(
        <>
        <div className="footer-bottom">
            <div className="default-container">
                <div className="row clearfix">
                    <div className="column col-md-6 col-sm-12 col-xs-12">
                        <div className="copyright">Copyrights &copy; 2022 Okell. Powered by: DevTheme</div>
                    </div>
                    <div className="column col-md-6 col-sm-12 col-xs-12">
                        <ul className="footer-nav">
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Legal Terms</Link></li>
                            <li><Link to="#">FAQ’s</Link></li>
                            <li><Link to="#">Support</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Copyright;