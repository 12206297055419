import React , { useState, useMemo } from 'react';
import Pagination from '../../setup/Pagination';
import ProjectData from '../../data/project/ProjectData.json';

let PageSize = 9;

const ProjectsPage = () => {

    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return ProjectData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return(
        <>
        {/*Blog Section*/}
        <section className="blog-section section-padding-all">
            <div className="default-container">
                <div className="row clearfix">
                        <div className="con-title-column col-lg-12 col-md-12 col-sm-12">
                        {/*Sec Title*/}
                            <div className="sec-con-title text-center centered mx-auto">
                                <div className="con-title-text con-title-border-l">Welcome to visit our products</div>
                                <h2>Our Most Popular &amp; Best Selling Items </h2>
                                <div className="text">We have designed our products to be user-friendly and therefore able to meet the needs of the users</div>
                            </div>
                        </div>
                </div>
                <div className="row clearfix mt-3">
                    {currentTableData.map(item => {
                    return (
                    <div className="col-lg-4 mb-4">
                        <div className="img_blog">
                            <div className="item">
                                <img src={`${item.image}`} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </div>
                        <div className="blog-box-detail p-4">
                            <div className="mt-0">
                                <h5 className="font-weight-bold"><a target={"_blank"} href={`${item.downloadlink}`} className="read-more">{item.title.substring(0, 30)+'...'}</a></h5>
                                <p className="text-muted">{item.details.substring(0, 70)+'...'}</p>
                                <ul className='pd-link'>
                                    <li><a target={"_blank"} className="corpo-r-btn btn-style-two sp-btn" href={`${item.previewlink}`}><i class="fa fa-eye"></i>Preview</a></li>
                                    <li><a target={"_blank"} className="corpo-r-btn btn-style-three sp-btn" href={`${item.downloadlink}`}><i class="fa fa-cloud-download"></i>Download</a></li>
                                </ul>
                                <span className='technology-icon'><img src={`images/icons/${item.technoicon}`} alt={item.title} className="tec-icon" /></span>
                            </div>
                        </div>
                    </div>
                    );
                    })}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                        <Pagination
                            className="pagination-bar text-right"
                            currentPage={currentPage}
                            totalCount={ProjectData.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                </div>
            </div>
        </section>
        {/*End Blog Section*/}
        </>
    )
}

export default ProjectsPage;