import React from 'react';
import HeaderOne from '../../common/header/HeaderOne';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BlogPostSingleFullWidth from '../../components/blog-details-components/BlogPostSingleFullWidth';
//import GetStart from '../../components/section-components/GetStart';
import FooterTwo from '../../common/footer/FooterTwo';

const BlogDetailsOne = () => {
    return (
        <>
        <HeaderOne />

        <BreadcrumbOne 
            title="Blog Details Full Width"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Blog Details Full Width"
        />
        
        <BlogPostSingleFullWidth />
        
        {/* <GetStart /> */}
        
        <FooterTwo />
        </>
    )
}
    
export default BlogDetailsOne;