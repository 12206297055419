import React from 'react';
import { Link } from 'react-router-dom';

const GetStart = () => {
    return(
        <>
        {/*Get A Quote Section*/}
        <section className="get-quote-section section-padding-two">
            <div className="default-container">
            <div className="row clearfix">
                <div className="col-lg-9 text-white">
                <h1> Want to start a new project with us? Let’s Start! </h1>
                </div>
                <div className="col-lg-3">
                <Link className="corpo-r-btn btn-style-two" to="#"> start now! </Link>
                </div>
            </div>
            </div>
        </section>
        {/*End Get A Quote Section*/}
        </>
    )
}

export default GetStart;