import React from 'react';
import { Link } from 'react-router-dom';
import PostData from '../../data/blog/PostData.json';
import Copyright from './Copyright';

const Footer = () => {
    return(
        <>
        {/*Main Footer*/}
        <footer className="footer">
            <div className="default-container">
            {/*Widgets Section*/}
            <div className="widgets-section">
                <div className="row clearfix">
                {/*Column*/}
                <div className="big-column col-lg-12">
                    <div className="row clearfix">
                    {/*Footer Column*/}
                    <div className="footer-col col-lg-3">
                        <div className="footer-widget logo-widget">
                        <div className="logo">
                            <Link to="/"><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" /></Link>
                        </div>
                        <div className="text">California, TX 70240</div>
                        <ul className="list-style-one">
                            <li><span>Phone:</span> +123 456 7890</li>
                            <li><span>Email:</span> support@Corpr-R.com</li>
                            <li><span>Working Hours:</span> Mon - Fri: 9.00am - 5.00pm</li>
                        </ul>
                        <ul className="social-icon">
                            <li><Link to="#"><i className="fa fa-facebook" /></Link></li>
                            <li><Link to="#"><i className="fa fa-linkedin" /></Link></li>
                            <li><Link to="#"><i className="fa fa-vimeo" /></Link></li>
                            <li><Link to="#"><i className="fa fa-google-plus" /></Link></li>
                            <li><Link to="#"><i className="fa fa-twitter" /></Link></li>
                        </ul>
                        </div>
                    </div>
                    <div className="footer-col col-lg-3">
                        <div className="footer-widget posts-widget">
                            <h2>Recent Posts</h2>
                            { 
                                PostData.slice( 0, 3 ).map((item) => (
                                <article className="post">
                                    <div className="text"><Link to={`/single-post-fullwidth/${item.id}`}>{item.title}</Link></div>
                                    <ul className="post-date">
                                    <li>By {item.admin}</li>
                                    <li>{item.date}</li>
                                    </ul>
                                </article>
                                ))
                            }
                        </div>
                    </div>
                    <div className="footer-col col-lg-3">
                        <div className="footer-widget links-widget">
                        <h2>Quick Links</h2>
                        <ul className="footer-link">
                            <li><Link to="#">About Us</Link></li>
                            <li><Link to="#">Our Services</Link></li>
                            <li><Link to="#">Testimonials</Link></li>
                            <li><Link to="#">Our Projects</Link></li>
                            <li><Link to="#">Latest News</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Contact Us</Link></li>
                        </ul>
                        </div>
                    </div>
                    <div className="footer-col col-lg-3">
                        <div className="footer-widget newsletter-widget">
                        <h2>Our Newsletter</h2>
                        <div className="text">Get in your inbox the latest News and Offers from Corpr-R</div>
                        <div className="newsletter-form">
                            <form method="post" action="#">
                            <div className="form-group">
                                <input type="email" name="email" placeholder="Email address" required />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="corpo-r-btn btn-style-one">Subscribe</button>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div> 
            <Copyright />
        </footer>
        {/*End Main Footer*/}
        </>
    )
}

export default Footer;